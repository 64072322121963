import React from 'react';

type Props = {
  className: string;
};

const Logo: React.FC<Props & React.SVGProps<SVGSVGElement>> = ({
  ...props
}) => {
  return (
    <svg
      viewBox="0 0 92 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M51.9067 23.3794H56.012C56.0519 23.3822 56.092 23.377 56.1298 23.3641C56.1676 23.3512 56.2023 23.3308 56.2319 23.3043C56.2614 23.2778 56.2852 23.2457 56.3017 23.2098C56.3182 23.174 56.3271 23.1353 56.3279 23.096V11.9579C56.3279 8.79944 57.9074 7.1352 60.6344 7.1352C63.3614 7.1352 64.912 8.79944 64.912 11.9579V23.0974C64.9128 23.1367 64.9217 23.1754 64.9382 23.2113C64.9547 23.2471 64.9785 23.2792 65.008 23.3057C65.0376 23.3322 65.0723 23.3526 65.1101 23.3655C65.1479 23.3784 65.188 23.3836 65.2279 23.3808H69.3332C69.3731 23.3836 69.4132 23.3784 69.451 23.3655C69.4888 23.3526 69.5235 23.3322 69.5531 23.3057C69.5826 23.2792 69.6064 23.2471 69.6229 23.2113C69.6394 23.1754 69.6483 23.1367 69.6491 23.0974V11.9862C69.6491 7.21951 66.9509 3.04582 60.6337 3.04582C54.3164 3.04582 51.5908 7.21951 51.5908 11.9862V23.0974C51.5918 23.1366 51.6008 23.1752 51.6174 23.2108C51.634 23.2465 51.6578 23.2784 51.6873 23.3048C51.7169 23.3312 51.7515 23.3514 51.7892 23.3642C51.827 23.377 51.8669 23.3822 51.9067 23.3794Z"
        fill="currentColor"
      />
      <path
        d="M23.6564 23.6337C25.1493 23.6337 26.1252 23.4644 26.5558 23.295C26.62 23.2698 26.6756 23.2273 26.7163 23.1724C26.757 23.1175 26.7811 23.0525 26.7858 22.9847V19.6548C26.7858 19.4572 26.6993 19.3445 26.4411 19.3445C26.2399 19.3445 25.6658 19.4572 25.2063 19.4572C23.4263 19.4572 22.6517 18.8089 22.6517 17.2857V7.89679H26.4411C26.5246 7.89605 26.6045 7.86312 26.6636 7.80508C26.7227 7.74705 26.7562 7.66855 26.757 7.58647V3.9484C26.7562 3.86632 26.7227 3.78782 26.6636 3.72978C26.6045 3.67175 26.5246 3.63882 26.4411 3.63808H22.5082V0.479645C22.5082 0.140988 22.3639 0 22.1057 0H18.2298C17.9146 0 17.7711 0.141697 17.7711 0.479645V17.7667C17.7711 21.9695 20.1534 23.6337 23.6564 23.6337Z"
        fill="currentColor"
      />
      <path
        d="M7.17845 23.859C11.8008 23.859 15.1027 21.0668 15.1027 17.4287C15.1027 13.3394 12.3461 12.1555 8.58558 11.3655L6.71902 10.9709C5.31261 10.6606 4.53729 10.0116 4.53729 8.91202C4.53729 7.50214 5.97254 6.74051 7.95378 6.74051C10.7385 6.74051 12.6331 7.98107 12.9202 7.98107C13 7.97587 13.0768 7.94954 13.1426 7.90488C13.2084 7.86022 13.2606 7.79891 13.2938 7.72744L14.0403 4.99197C14.1845 4.62497 14.155 4.54066 13.896 4.39967C12.2307 3.55374 10.4507 3.0741 7.92421 3.0741C3.24483 3.0741 0 5.72525 0 9.25068C0 12.5806 2.61303 14.4113 6.34687 15.202L8.24155 15.5966C9.76335 15.9069 10.6216 16.4992 10.6216 17.7681C10.6216 19.0653 9.58809 20.1933 7.14744 20.1933C3.7021 20.1933 1.34799 18.84 0.975109 18.84C0.745035 18.84 0.659209 18.9527 0.573382 19.319L0.0851052 21.6039C-0.0591418 22.0828 0.0851055 22.3931 0.401006 22.5341C1.57878 23.1824 4.79405 23.859 7.17845 23.859Z"
        fill="currentColor"
      />
      <path
        d="M38.4997 19.939C35.485 19.939 32.7298 17.7108 32.7298 13.5087C32.7298 9.30671 35.4857 7.13237 38.4997 7.13237C41.5426 7.13237 44.2415 9.30388 44.2415 13.5087C44.2415 17.7391 41.5426 19.939 38.4997 19.939ZM38.4997 24C44.5285 24 48.9208 19.5727 48.9208 13.5087C48.9208 7.44481 44.5285 3.04582 38.4997 3.04582C32.4991 3.04582 28.0779 7.44552 28.0779 13.5087C28.0779 19.572 32.4991 24 38.4997 24Z"
        fill="currentColor"
      />
      <path
        d="M92 13.2834C92 7.04876 88.3534 3.04582 82.5518 3.04582C77.0971 3.04582 72.3889 7.16354 72.3889 13.4244C72.3889 20.1083 77.2118 24 82.3795 24C86.7148 24 89.216 22.3216 90.7631 20.2776C91.0624 19.8816 91.1237 19.7108 91.1237 19.6003C91.1237 19.4898 91.0516 19.4034 90.7912 19.1752L88.9824 17.6243C88.5496 17.2581 88.4054 17.1447 88.2611 17.1447C88.1169 17.1447 88.031 17.2297 87.9164 17.3707C86.6816 19.0066 84.9593 19.9368 82.634 19.9368C79.7008 19.9368 77.7462 17.9793 77.25 14.8039H90.4984C91.4223 14.806 92 14.5205 92 13.2834ZM82.3816 6.96587C85.5147 6.96587 87.1728 9.04245 87.2629 11.3372H77.32C77.8645 8.87312 79.5674 6.96587 82.3816 6.96587Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Logo;
